<template>
  <b-card>
    <div
      class="mt-4 mb-2"
    >
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <div
          slot="emptystate"
          class="text-center my-5"
        >
          <h3
            v-if="payers.length>0"
          >
            Su búsqueda no coincide
          </h3>
          <b-overlay
            id="overlay-background"
            :show="show"
            rounded="lg"
            variant="bg-light"
            opacity="1.00"
            no-wrap
            no-fade
            class="mt-5"
          />
          <h3
            v-if="!show && payers.length==0"
          >
            Aún no han registrado pagadores
          </h3>
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ getStatus(props.row.status) }}
            </b-badge>
          </span>

          <!-- Column: BillingAmount -->
          <span v-else-if="props.column.field === 'billingAmount'">
            {{ props.row.currency === 'Soles' ? 'S/' + props.formattedRow[props.column.field] : '$' + props.formattedRow[props.column.field] }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                variant="primary"
                :to="{ name:'Admin-payer-detail', params:{ id: props.row._id } }"
                block
              >
                Ver detalle
              </b-button>
            </span>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mostrando grupos de
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> de {{ props.total }} registro(s) </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BBadge, BPagination, BFormSelect, BButton, BCard, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BBadge,
    BPagination,
    BFormSelect,
    BButton,
    BCard,
  },
  data() {
    return {
      show: true,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Ingresado por',
          field: 'client.names',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar cliente',
          },
        },
        {
          label: 'R.U.C',
          field: 'ruc',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar R.U.C',
          },
        },
        {
          label: 'Razón social',
          field: 'businessName',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar razón social',
          },
        },
        {
          label: 'Estatus',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar estatus',
            filterDropdownItems: [
              { value: 'notApproved', text: 'Pendiente' },
              { value: 'approved', text: 'Aceptado' },
              { value: 'rejected', text: 'Rechazado' },
            ],
          },
        },
        {
          label: 'Línea con el pagador',
          field: 'billingAmount',
          tdClass: 'text-right',
          type: 'text',
          formatFn: this.moneyFormat,
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar línea',
          },
        },
        {
          label: 'Línea disponible',
          field: 'disponibleAmount',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar l.disponible',
          },
        },
        {
          label: 'Detalle',
          field: 'action',
        },
      ],
      rows: [],
      payers: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        current      : 'light-primary',
        approved : 'light-success',
        rejected     : 'light-danger',
        notApproved     : 'light-warning',
        applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    useJwt.getAllPayers({
      email: this.userEmail,
    }).then(res => {
      this.rows = res.data.payers ? res.data.payers : []
      this.payers = this.rows.length > 0 ? this.rows : []
      this.show = false
    })
  },
  methods: {
    moneyFormat(money) {
      return !Number.isNaN(Number.parseFloat(money)) ? ''.concat(Number.parseFloat(money).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : '-'
    },
    getStatus(status) {
      let label = ''
      switch (status) {
        case 'rejected':
          label = 'Rechazado'
          break
        case 'notApproved':
          label = 'Pendiente'
          break
        case 'approved':
          label = 'Aceptado'
          break
        default:
          break
      }
      return label
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
